import { AxiosQuery } from "../api/AxiosQuery";
import qs from 'qs';
import { EspacioModel } from "../models/EspacioModel";
import { ResponseModel } from "../models/ResponseModel";

export const getListaEspacioService = async(): Promise<any> => {
    let query = qs.stringify({ 
        fields: ['id', 'nombre'],
    },{
        encodeValuesOnly: true
    }); 
    return await AxiosQuery('GET', '/api/res-espacios', query).then((response: ResponseModel) => {
        if(response.status === 200){
            response.data = response.data.map((espacio: any) => { return new EspacioModel(espacio); });
        }
        
        return response;
    });
}