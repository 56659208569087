import { Menubar } from 'primereact/menubar';
import { useAuth } from '../../auth/AuthContext';
import './Navbar.scss';
import {ReactComponent as IconIMJ} from '../../assets/icons/ByImj.svg'

const Navbar = () => {
    const { loggedUser } = useAuth();

    // const start = <img alt="logo" src={require('../../assets/icons/ByImj.svg')} onError={(e) => (e.target as any).src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} height="40" className="mr-2"></img>;

    const start = <IconIMJ width={120} height={30} />
    return (
        // <React.Fragment>
        //     <Navbar bg="dark" expand="lg" className="navbar-dark">
        //         <Container>
        //             <Navbar.Brand>React Auth Demo | Portal</Navbar.Brand>
        //             <Navbar.Toggle aria-controls="basic-navbar-nav" />
        //             <Navbar.Collapse id="basic-navbar-nav">
        //                 <Nav className="ms-auto">
        //                     <Nav.Link>
        //                         <Button className="btn-warning" onClick={logout}>Logout</Button>
        //                     </Nav.Link>
        //                 </Nav>
        //             </Navbar.Collapse>
        //         </Container>
        //     </Navbar>
        // </React.Fragment>
        <Menubar model={[{label: (loggedUser() as any).nombre, icon: 'pi pi-fw pi-user'} ,{label: 'Salir', icon: 'pi pi-fw pi-sign-out', url:'/#/logout' }]} start={start} className="justify-content-between imj-menubar-sticky" />  
    );
}
export default Navbar;