import { createContext, useContext } from "react";
import { LoginParam } from "../models/LoginModel";
import { RolModel } from "../models/RolModel";

// export const AuthContext = createContext({
//     token: '',
//     onLogin: {},
//     onLogout: {},
//     isLoading: false
// });

// interface AuthContextType {
// 	user: any;
// 	signin: (user: LoginParam, onLogin: Function, onError: Function) => void;
// 	signout: (callback: Function) => void;
// 	loggedUser: () => String;
// 	isAuthenticated: () => Boolean;
// 	rol: () => String;
// 	usuarioid: () => Number;
// 	validateToken: Function;
// 	// proveedor: () => Promise<number>;
// }

interface AuthContextType {
	user: any;
	signin: (user: LoginParam) => void;
	signout: () => void;
	loggedUser: () => any;
	rol: RolModel;
}

// export const AuthContext = createContext({
//     token: '',
//     onLogin: () => {},
//     onLogout: () => {},
//     isLoading: false
// });

export const AuthContext = createContext<AuthContextType>({
	user: null,
	signin: (user: LoginParam) => {
		return null;
	},
	signout: () => null,
	loggedUser: () => {},
	rol: {}
});
    
export const useAuth = () =>{
    const authHelpers = useContext(AuthContext);

    return authHelpers;
}