import { EspacioModel } from "./EspacioModel";
import { ParticipanteModel } from "./ParticipanteModel";
import { UsuarioModel } from "./UsuarioModel";

export class ReservacionModel{
    public id?: number | null;
    public res_espacio?: EspacioModel | undefined;
    public titulo?: string;
    public descripcion?: string;
    public fecha_inicio?: Date | null;
    public fecha_fin?: Date | null;
    public reservado_por?: UsuarioModel;
    public reservado_para?: UsuarioModel;
    public res_participantes?: ParticipanteModel[];

    constructor(reservacion?: any){
        reservacion = reservacion || {
            id: null,
            res_espacio: {},
            titulo: '',
            descripcion: '',
            fecha_inicio: null,
            fecha_fin: null,
            reservado_por: null,
            reservado_para: null,
            res_participantes: []
        }

        this.id = reservacion?.id;
        this.res_espacio = reservacion?.res_espacio;
        this.titulo = reservacion?.titulo;
        this.descripcion = reservacion?.descripcion;
        this.fecha_inicio = reservacion?.fecha_inicio;
        this.fecha_fin = reservacion?.fecha_fin;
        this.reservado_por = reservacion?.reservado_por;
        this.reservado_para = reservacion?.reservado_para;
        this.res_participantes = reservacion?.res_participantes;
    }
}

export class ReservacionFormModel{
    public id?: number | null;
    public res_espacio?: number;
    public titulo?: string;
    public descripcion?: string;
    public fecha_inicio?: Date | null;
    public fecha_fin?: Date | null;
    public reservado_por?: UsuarioModel;
    public reservado_para?: UsuarioModel;
    public res_participantes?: UsuarioModel[];

    constructor(reservacion?: any){
        reservacion = reservacion || {
            id: null,
            res_espacio: null,
            titulo: '',
            descripcion: '',
            fecha_inicio: null,
            fecha_fin: null,
            reservado_por: null,
            reservado_para: null,
            res_participantes: []
        }

        this.id = reservacion?.id;
        this.res_espacio = reservacion?.res_espacio;
        this.titulo = reservacion?.titulo;
        this.descripcion = reservacion?.descripcion;
        this.fecha_inicio = reservacion?.fecha_inicio;
        this.fecha_fin = reservacion?.fecha_fin;
        this.reservado_por = reservacion?.reservado_por;
        this.reservado_para = reservacion?.reservado_para;
        this.res_participantes = reservacion?.res_participantes;
    }
}

export class AreaModel{
    public id?: number;
    public nombre?: string;
}

export class ReservacionParticipanteModel{
    public id?: number;
    public participantes?: UsuarioModel[];

    constructor(rp?: any){
        rp = rp || {
            id: null,
            participantes: []
        }

        this.id = rp.id;
        this.participantes = rp.participantes;
    }
}

export const INITIAL_RESERVACIONES: ReservacionModel[] = [
    // {
    //   id: createEventId(),
    //   titulo: 'Reunión Reunión Reunión Reunión Reunión Reunión',
    //   fechaInicio: todayStr + 'T12:30:00',
    //   fechaFin: todayStr + 'T13:00:00'
    // },
    // {
    //   id: createEventId(),
    //   titulo: 'Evento',
    //   fechaInicio: todayStr + 'T14:00:00',
    //   fechaFin: todayStr + 'T16:00:00'
    // },
    // {
    //   id: createEventId(),
    //   titulo: 'Reunion de Director',
    //   fechaInicio: todayStr + 'T17:00:00',
    //   fechaFin: todayStr + 'T18:00:00'
    // },
    // {
    //   id: createEventId(),
    //   titulo: 'Vamos a jugar 1',
    //   fechaInicio: todayStr + 'T18:00:00',
    //   fechaFin: todayStr + 'T18:30:00'
    // },
    // {
    //   id: createEventId(),
    //   titulo: 'Vamos a jugar 2',
    //   fechaInicio: todayStr + 'T20:00:00',
    //   fechaFin: todayStr + 'T21:30:00'
    // },
    // {
    //   id: createEventId(),
    //   titulo: 'Vamos a jugar 3',
    //   fechaInicio: todayStr + 'T22:00:00',
    //   fechaFin: todayStr + 'T23:00:00'
    // }
    // {
    //     id: createEventId(),
    //     res_espacio: new EspacioModel({id: 1, nombre: 'Sala'}),
    //     titulo: 'Reunión Jorge',
    //     descripcion: 'Sobre el proyecto de Cronos',
    //     fecha_inicio: new Date('2023-02-22T12:30:00'),
    //     fecha_fin: new Date('2023-02-22T13:00:00')
    // },
    // {
    //     id: createEventId(),
    //     res_espacio: new EspacioModel({id: 1, nombre: 'Sala'}),
    //     titulo: 'Evento',
    //     fecha_inicio: new Date('2023-02-22T14:00:00'),
    //     fecha_fin: new Date('2023-02-22T16:00:00')
    // },
    // {
    //     id: createEventId(),
    //     res_espacio: 1,
    //     titulo: 'Reunión director Juan',
    //     descripcion: 'Que realizan en esta semana',
    //     fecha_inicio: new Date('2023-02-22T17:00:00'),
    //     fecha_fin: new Date('2023-02-22T18:00:00')
    // },
    // {
    //     id: createEventId(),
    //     res_espacio: 4,
    //     titulo: 'Masaje Adan',
    //     fecha_inicio: new Date('2023-02-22T18:00:00'),
    //     fecha_fin: new Date('2023-02-22T18:30:00'),
    // },
    // {
    //     id: createEventId(),
    //     res_espacio: 2,
    //     titulo: 'Junta Jorge',
    //     descripcion: 'Presenta el nuevo proyecto',
    //     fecha_inicio: new Date('2023-02-22T20:00:00'),
    //     fecha_fin: new Date('2023-02-22T21:30:00')
    // },
    // {
    //     id: createEventId(),
    //     res_espacio: 5,
    //     titulo: 'Evento de fubtol',
    //     descripcion: 'Jugar fubtol (IMJ)',
    //     fecha_inicio: new Date('2023-02-22T22:00:00'),
    //     fecha_fin: new Date('2023-02-22T23:00:00')
    // }
    // ,
    // {
    //     id: createEventId(),
    //     titulo: 'Reunión Lunes - Jorge - Juan - Adan - Olaf- Denisse - Victor - Rene',
    //     res_espacio: 2,
    //     fecha_inicio: new Date('2023-02-20T09:00:00'),
    //     fecha_fin: new Date('2023-02-20T10:00:00')
    // }
    // ,
    // {
    //     id: createEventId(),
    //     titulo: 'Reunión Lunes 2',
    //     res_espacio: 1,
    //     fecha_inicio: new Date('2023-02-20T13:00:00'),
    //     fecha_fin: new Date('2023-02-20T14:00:00')
    // },
    // {
    //     id: createEventId(),
    //     res_espacio: 3,
    //     titulo: 'Reunión Lunes 3',
    //     descripcion: 'El proyecto OBP',
    //     fecha_inicio: new Date('2023-02-20T16:00:00'),
    //     fecha_fin: new Date('2023-02-20T18:30:00')
    // },
    // {
    //     id: createEventId(),
    //     res_espacio: 4,
    //     titulo: 'Masaje Jorge',
    //     fecha_inicio: new Date('2023-02-21T09:00:00'),
    //     fecha_fin: new Date('2023-02-21T10:00:00')
    // }
]

export function createEventId() {
    //return eventGuid++;
    return Math.floor(Math.random() * 10000000);
}

export const getListaArea: AreaModel[] = [
    {
        id: 1, 
        nombre: 'Area de IT'
    }, 
    {
        id:2, 
        nombre: 'Sala de juntas'
    }, 
    {
        id: 3, 
        nombre: 'Sala de director'
    }, 
    {
        id: 4, 
        nombre: 'Sala de masaje'
    }, 
    {
        id: 5, 
        nombre: 'Cancha'
    }
]

export const getListaEmpleado: ListaEmpleado[] = [
    {
        area: 'Area IT',
        empleados: [
            {
                id: 1,
                nombre: 'Jorge',
                correo: 'jorge.correa@imjmedia.com.mx'
            },
            {
                id: 2,
                nombre: 'Olaf',
                correo: 'olaf.ruvalcaba@imjmedia.com.mx'
            },
            {
                id: 3,
                nombre: 'Rene',
                correo: 'rene.carrillo@imjmedia.com.mx'
            }
        ]
    },
    {
        area: 'Area Ventas',
        empleados:[
            {
                id: 11,
                nombre: 'Alejandra',
                correo: 'alejandra@imjmedia.com.mx'
            },
            {
                id:12,
                nombre: 'Angeles',
                correo: 'angeles@imgmedia.com.mx'
            }
        ]
    },
    {
        area: 'Area Diseñadores',
        empleados: [
            {
                id: 21,
                nombre: 'Luis',
                correo: 'luis@imjmedia.com.mx'
            }
        ]
    }
]

export class ListaEmpleado{
    public area?: string;
    public empleados?: Empleado[];
}

export class Empleado {
    public id?: number;
    public nombre?: string;
    public correo?: string;
}