export class UsuarioModel{
    public id?: number;
    public nombre?: string;
    public email?: string;

    constructor(usuario?: any){
        usuario = usuario || {
            id:  null,
            nombre: '',
            email: ''
        }

        this.id = usuario.id;
        this.nombre = usuario.nombre;
        this.email = usuario.email;
    }
}