import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';
// PrimeReaact
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
//icons
import "primeicons/primeicons.css";
import { addLocale, locale } from 'primereact/api';
import { HashRouter, Route, Routes } from 'react-router-dom';
import AuthProvider from './auth/AuthProvide';
import Login from './auth/login/Login';
import Logout from './auth/logout/Logout';
import NotFound from './components/not-found/NotFound';
import { ToastProvider } from './components/toast/Toast';
import CalendarioDeOcupacion from './modules/calendario-de-ocupacion/CalendarioDeOcupacion';
import ProtectedRoute from './util/ProtectedRoute';

// Moment JS Locale Mexico
import 'moment/locale/es-mx';

// Primereact configuracion de calendario
addLocale('es', {
  firstDayOfWeek: 1,
  dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
  dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
  dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
  monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
  monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
  today: 'Hoy',
  clear: 'Limpiar'
});
locale('es');


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <div className='imj-container'>
    <div className='imj-content'>
      <HashRouter>
        <ToastProvider>
          <AuthProvider>
            <Routes>
              <Route path='*' element={<NotFound />}/>
              <Route path='/login' element={<Login />} />
              <Route path="/" element={<App />}>
                <Route path='' element={
                  <ProtectedRoute>
                      <CalendarioDeOcupacion />
                  </ProtectedRoute>
                } />
              </Route>
              <Route path='/logout' element={<Logout />} />
            </Routes>
          </AuthProvider>
        </ToastProvider>
      </HashRouter>
    </div>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
