import { EspacioModel } from "./EspacioModel";
import { ParticipanteModel } from "./ParticipanteModel";
import { UsuarioModel } from "./UsuarioModel";

export class EnviarEmailModel{
    public id?: number | null;
    public res_espacio?: EspacioModel | undefined;
    public titulo?: string;
    public descripcion?: string;
    public fecha_inicio?: Date | null;
    public fecha_fin?: Date | null;
    public reservado_por?: UsuarioModel;
    public reservado_para?: UsuarioModel;
    public res_participantes?: ParticipanteModel[];
    public method?: string;
    public isCreated?: boolean;

    constructor(reservacion?: any){
        reservacion = reservacion || {
            id: null,
            res_espacio: {},
            titulo: '',
            descripcion: '',
            fecha_inicio: null,
            fecha_fin: null,
            reservado_por: null,
            reservado_para: null,
            res_participantes: [],
            method: '',
            isCreated: true
        }

        this.id = reservacion?.id;
        this.res_espacio = reservacion?.res_espacio;
        this.titulo = reservacion?.titulo;
        this.descripcion = reservacion?.descripcion;
        this.fecha_inicio = reservacion?.fecha_inicio;
        this.fecha_fin = reservacion?.fecha_fin;
        this.reservado_por = reservacion?.reservado_por;
        this.reservado_para = reservacion?.reservado_para;
        this.res_participantes = reservacion?.res_participantes;
        this.method = reservacion?.method;
        this.isCreated = reservacion?.isCreated;
    }
}