import { Toast } from 'primereact/toast';
import { ReactNode, createContext, useContext, useRef } from "react";

interface ToastContentType{
    onToastSuccess: (title: string, messeage: string) => void,
    onToastError: (title: string, messeage: string) => void
}

const ToastContext = createContext<ToastContentType>({
    onToastSuccess: () => {},
    onToastError: () => {}
});

export const ToastProvider = ({ children }: { children: ReactNode }) => {
    const toast = useRef<any>(null);

    const onToastSuccess = (title?: string, messeage?: string) =>{
        toast.current.show({severity:'success', summary: title ? title : null, detail: messeage, life: 3000});
    }

    const onToastError = (title?: string, messeage?: string) => {
        toast.current.show({severity:'error', summary: title ? title : null, detail: messeage, life: 3000});
    }

    let value = {
		onToastSuccess,
        onToastError
	};

    return(
        <ToastContext.Provider value={value}>
            <Toast ref={toast} position='bottom-center' />
            {children}
        </ToastContext.Provider>
    )

}

export const useToast = () =>{
    const toastHelpers = useContext(ToastContext);

    return toastHelpers;
}