import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from 'primereact/password';
import { classNames } from "primereact/utils";
import React, { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { LoginParam } from "../../models/LoginModel";
import { AuthContext } from "../AuthContext";
import './Login.scss';

const Login = () => {
    const { control, formState: { errors }, handleSubmit } = useForm<any>({defaultValues: {identifier: '', password: ''}});
    const { signin } = useContext(AuthContext);

    const onSubmitLogin = (event: LoginParam) => {
        //console.log(event);
        //setToken('ASDFGDFFD23');
        signin(event);
    }

    const getFormErrorMessage = (field: string) => {
        return errors[field] && <small className="p-error">{ errors[field]?.message?.toString() }</small>
    };

    return (
        <section>
            <div className="leaves">
                <div className="set">
                    <div><img height={60} width={60} src={require('../../assets/otono/hoja1.png')} alt="hoja1" /></div>
                    <div><img height={60} width={60} src={require('../../assets/otono/hoja2.png')} alt="hoja2" /></div>
                    <div><img height={60} width={60} src={require('../../assets/otono/hoja3.png')} alt="hoja3" /></div>
                    <div><img height={60} width={60} src={require('../../assets/otono/hoja1.png')} alt="hoja4" /></div>
                    <div><img height={60} width={60} src={require('../../assets/otono/hoja2.png')} alt="hoja5" /></div>
                    <div><img height={60} width={60} src={require('../../assets/otono/hoja3.png')} alt="hoja6" /></div>
                </div>
            </div>
            <img src={require('../../assets/otono/fondo_arbol_otono.png')} alt="fondo_arbol_otono" className="imj-login-bg" />
            <img src={require('../../assets/otono/bicicleta.png')} alt="bicicleta" className="imj-login-bicicleta" />
            <img src={require('../../assets/otono/arbol_otono.png')} alt="arbol_otono" className="imj-login-arbol" />
            <div className="imj-login p-4">
                <div className="p-card-title text-center">
                    <h2 className="mt-0 mb-4 imj-h2">Iniciar Sesión</h2>
                </div>
                <form onSubmit={handleSubmit(onSubmitLogin)} className="p-fluid grid">
                    {/* Usuario */}
                    <div className="field col-12">
                        <span className="p-float-label">
                        <Controller name="identifier" control={control} rules={{ required: 'identifier es requierdo.' }} render={({ field, fieldState }) => (
                            <InputText id={field.name} {...field}  autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        <label htmlFor="identifier" className={classNames({ 'p-error': errors.identifier, 'imj-label': true })}>Usuario*</label>
                        </span>
                        {getFormErrorMessage('identifier')}
                    </div>
                    {/* Contrasenia */}
                    <div className="field col-12">
                        <span className="p-float-label">
                            <Controller name="password" control={control} rules={{ required: 'Contraseña es requerida.'}} render={({ field, fieldState }) => (
                                <Password id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} feedback={false} toggleMask />
                            )} />
                            <label htmlFor="password" className={classNames({ 'p-error': errors.password, 'imj-label': true })}>Contraseña*</label>
                        </span>
                        {getFormErrorMessage('password')}
                    </div>
                    <div className="col-12">
                        <Button type="submit" label='Iniciar Sesión' />
                    </div>
                </form>
            </div>
        </section>
    );
}
export default Login;