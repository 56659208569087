export const AUTH_TOKEN = 'token';

export const getTokenLS = () => {
    return localStorage.getItem(AUTH_TOKEN) || '';
}

export const setTokenLS = (valor: string | undefined) => {
    if(valor)
        localStorage.setItem(AUTH_TOKEN, valor);
}

export const removeTokenLS = () =>{
    localStorage.removeItem(AUTH_TOKEN);
}

export const SESSION_UUID = 'sessionUUID';

export const getSessionUUIDLS = () => {
    return localStorage.getItem(SESSION_UUID);
}

export const setSessionUUIDLS = (valor: string | undefined) => {
    if(valor)
        localStorage.setItem(SESSION_UUID, valor);
}

export const removeSessionUUIDLS = () =>{
    localStorage.removeItem(SESSION_UUID);
}

export const USER = 'u';

export const getUserLS = () => {
    return localStorage.getItem(USER);
}

export const setUserLS = (valor: string | undefined) => {
    if(valor)
        localStorage.setItem(USER, valor);
}

export const removeUserLS = () =>{
    localStorage.removeItem(USER);
}