import { AxiosQuery } from "../api/AxiosQuery";
import qs from 'qs';
import { ParticipanteModel } from "../models/ParticipanteModel";

export const saveParticipanteService = async(participante: ParticipanteModel) =>{
    
    let query = qs.stringify({ 
        fields: ['id'],
    },{
        encodeValuesOnly: true
    }); 
    
    return await AxiosQuery('POST', '/api/res-participantes', query, {data: participante});
}

export const removeParticipanteService = async(id: number) =>{
    return await AxiosQuery('DELETE', '/api/res-participantes/' + id,'');
}