export class RolModel{
    public id?: number;
    public name?: string;
    public description?: string;
    public type?: string;

    constructor(rol?: any){
        rol = rol || {
            id: null,
            name: '',
            description: '',
            type: ''
        }

        this.id = rol.id;
        this.name = rol.name;
        this.description = rol.description;
        this.type = rol.type;
    }
}