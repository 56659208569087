import { UsuarioModel } from "./UsuarioModel";

export class AreaTrabajoModel{
    public id?: number;
    public nombre?: string;
    public color?: string;
    public personal_imjs?: ATPersonalImjModel[];

    constructor(at?: any){
        at = at || {
            id: null,
            nombre: '',
            color: '',
            personal_imjs: []
        }

        this.id = at.id;
        this.nombre = at.nombre;
        this.color = at.color;
        this.personal_imjs = at.personal_imjs;
    }
}

export class ATPersonalImjModel{
    public id?: number;
    public puesto?: string;
    public usuario_sistema?: UsuarioModel;
}

export class AreaTrabajoSelectModel{
    public id?: number;
    public nombre?: string;
    public color?: string;
    public usuarios?: UsuarioModel[]
}