import React, { useEffect } from 'react';
import { useAuth } from '../AuthContext';

const Logout = () =>{

    const { signout } = useAuth();

    useEffect(() => {
        signout();
        // eslint-disable-next-line
    }, []);

    return (
        <></>
    );
}

export default Logout;