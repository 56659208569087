import React from 'react';
import './Card.scss';

const Card = (props: any) => {

  const cargaCard = () => {
    return(
      <div className='flex flex-row w-full h-full justify-content-between imj-card'>
        <div className='imj-card-section1 flex w-full justify-content-center align-items-center overflow-hidden mr-1'>
          <b className='card-week-and-day-time mr-2'>{props.event.timeText}</b>
          <i className='card-week-and-day-title imj-card-title'>{props.event.event.title}</i>
        </div>
        <div className='imj-card-area-background' style={{backgroundColor: props.event.event.extendedProps.colorArea ? props.event.event.extendedProps.colorArea : '#e8474fc7'}}>
        </div>
      </div>
    )
  }

  return (
    <>
      {cargaCard()}
    </>
  );
}

export default Card;