import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getTokenLS } from "../helpers/LocalStorageIMJ";

const ProtectedRoute = (props: any) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const checkToken = () => {
        const token = getTokenLS();
        if (!token || token === 'undefined') {
            setIsLoggedIn(false);
            return navigate('/login');
        }
        setIsLoggedIn(true);
    }
    
    useEffect(() => {
        checkToken();
        // eslint-disable-next-line
    }, [isLoggedIn]);

    return (
        <React.Fragment>
            {
                isLoggedIn ? props.children : null
            }
        </React.Fragment>
    );
}
export default ProtectedRoute;