import React from "react";
import './Footer.scss';

const Footer = () => {
    return (
        <div className="imj-container-footer">
            <div className="flex justify-content-center">
                <footer>
                    &copy; By IMJ Media 2023
                </footer>
            </div>
        </div>
    );
}
export default Footer;