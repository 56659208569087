import { ReservacionModel } from "./ReservacionModel";
import { UsuarioModel } from "./UsuarioModel";

export class ParticipanteModel{
    public id?: number;
    public participante?: UsuarioModel;
    public res_reservacion?: ReservacionModel 

    constructor(participante?: any){
        participante = participante || {
            id: null,
            participante: {},
            res_reservacion: {}
        }

        this.id = participante.id;
        this.participante = participante.participante;
        this.res_reservacion = participante.res_reservacion;
    }
}