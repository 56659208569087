import React, { useEffect, useState } from 'react';
import { Outlet } from "react-router-dom";
import './App.scss';
import Footer from './components/footer/Footer';
import Navbar from './components/navbar/Navbar';

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const checkUserToken = () => {
        const userToken = localStorage.getItem('token');
        if (!userToken) {
            setIsLoggedIn(false);
            return;
        }
        setIsLoggedIn(true);
    }
    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);

  return (
    <React.Fragment>
        {isLoggedIn && <Navbar />}
       
          <Outlet />
       
        {isLoggedIn && <Footer />}
    </React.Fragment>
  );
}

export default App;
