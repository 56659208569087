import qs from 'qs';
import { AxiosQuery } from "../api/AxiosQuery";
import { AreaTrabajoModel, AreaTrabajoSelectModel } from "../models/AreaTrabajoModel";
import { ResponseModel } from "../models/ResponseModel";
import { UsuarioModel } from "../models/UsuarioModel";

export const getListaAreaTrabajoService = async(): Promise<any> => {
    let query = qs.stringify({ 
        fields: ['id', 'nombre'],
        populate: {
            personal_imjs: {
                fields: ['id', 'puesto'],
                populate: {
                    usuario_sistema: {
                        fields: ['id', 'nombre', 'email']
                    }
                }
            }
        }
    },{
        encodeValuesOnly: true
    });
    return await AxiosQuery('GET', '/api/area-trabajos', query, '').then((response: ResponseModel) => {
        if(response.status === 200){
            console.log(response)
        }
        return false;
    });
}

export const getListaAreaTrabajoSelectService = async(): Promise<any> => {
    let query = qs.stringify({ 
        fields: ['id', 'nombre', 'color'],
        populate: {
            personal_imjs: {
                fields: ['id', 'puesto'],
                populate: {
                    usuario_sistema: {
                        fields: ['id', 'nombre', 'email']
                    }
                }
            }
        }
    },{
        encodeValuesOnly: true
    });
    
    return await AxiosQuery('GET', '/api/area-trabajos', query, '').then((response: ResponseModel) => {
        if(response.status === 200){
            let listaSeleccionAreaTrabajo: AreaTrabajoSelectModel[] = []
            const listaAreaTrabajo: AreaTrabajoModel[] = response.data.map((a: any) => { return new AreaTrabajoModel(a); });
            listaAreaTrabajo.forEach(a => {
                let nuevoSeleccionAreaTrabajo: AreaTrabajoSelectModel = new AreaTrabajoSelectModel();
                nuevoSeleccionAreaTrabajo.id = a.id;
                nuevoSeleccionAreaTrabajo.nombre = a.nombre;
                nuevoSeleccionAreaTrabajo.color = a.color;
                let listaUsuario: UsuarioModel[] = [];
                a.personal_imjs?.forEach(pi => {
                   
                    let nuevoUsuario: UsuarioModel = new UsuarioModel();
                    nuevoUsuario.id = pi.usuario_sistema?.id;
                    nuevoUsuario.nombre = pi.usuario_sistema?.nombre;
                    nuevoUsuario.email = pi.usuario_sistema?.email;

                    listaUsuario.push(nuevoUsuario);
                    
                });

                nuevoSeleccionAreaTrabajo.usuarios = listaUsuario;
                listaSeleccionAreaTrabajo.push(nuevoSeleccionAreaTrabajo);
            });
            response.data = listaSeleccionAreaTrabajo;
        }
        
        return response;
    });
}