// Ojo, es una funcion, las funciones empiezan con minuscula
// es que vi en un curso de react dice que tiene que agregar un archivo con mayucula 
// la verdad da igual xD solo es una forma de acomodar, es que aqui solo usamos los componentres en may
// iniciemos con el axios por cierto usamos async o no necesitamos 

import axios from "axios";
import { NormalizeData } from "../helpers/NormalizeData";
import { getTokenLS } from "../helpers/LocalStorageIMJ";
import { ResponseModel } from "../models/ResponseModel";

export const AxiosQuery = async (method: string, url:string, query:any, data?: any) => {

    const headers = url === '/api/auth/local' ? { } : { Authorization: 'Bearer ' + JSON.parse(getTokenLS())};

    const request = {
        url: url + '?' + query,
        method,
        headers,
        baseURL: process.env.REACT_APP_BASE_URL,
        data
        
    };

    //console.log(request)

    // proque yo no agrego el ?

    //console.log(request)
    let responseModel: ResponseModel = new ResponseModel();
    try{
        const response = await axios.request(request);
        //console.log(response)
        responseModel.data = NormalizeData(response.data);
        //responseModel.data = response.data;
        responseModel.status = response.status;

        return responseModel;
    }catch(error: any){
        console.log(error)
        responseModel.message = error.message;
        responseModel.status = 404
        return responseModel;
    }
}