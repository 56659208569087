import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import uuid from 'react-uuid';
import { useToast } from "../components/toast/Toast";
import { getSessionUUIDLS, getTokenLS, getUserLS, removeSessionUUIDLS, removeTokenLS, removeUserLS, setSessionUUIDLS, setTokenLS, setUserLS } from "../helpers/LocalStorageIMJ";
import { LoginParam } from "../models/LoginModel";
import { ResponseModel } from "../models/ResponseModel";
import { RolModel } from "../models/RolModel";
import { getLoginTokenService } from "../services/LoginService";
import { getUsuarioService } from "../services/UsuarioService";
import { AuthContext } from "./AuthContext";

const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [token, setToken] = useState('');
    const navigate = useNavigate();
    const { onToastSuccess, onToastError } = useToast();

    const authToken = getTokenLS();

    const user = () => {
		getUserLS();
	};

    const decrypt = (valor: any) => {
		let key = getSessionUUIDLS();
		if (key === null || key === undefined || valor === null || valor === undefined) {
			return null;
		} else {
			return JSON.parse(
				(window as any)['CryptoJS'].TripleDES.decrypt(valor, key).toString(
					(window as any)['CryptoJS'].enc.Utf8
				)
			);
		}
	};

    const signout = () => {
		removeTokenLS();
        removeUserLS();
        removeSessionUUIDLS();
        setTimeout(() => {
            navigate('/login');
        }, 500);
	};

    useEffect(() => {
        if (authToken) {
            //setToken(authToken);
            setToken(authToken);
        }
    }, [authToken]);   

    const signin = async (loginData: LoginParam) => {
		getLoginTokenService(loginData).then((response: ResponseModel) => {
            if(response.status === 200){
				let sessionUUID = uuid().toString();
                setTokenLS(JSON.stringify(response.data.jwt))
                setSessionUUIDLS(sessionUUID);

				getUsuarioService(response.data?.user?.id).then((responseU: ResponseModel) => {
                //getUsuarioService(313).then((responseU: ResponseModel) => {
                    if(responseU.status === 200){
                        let newUser = responseU.data;
                        let encryptedUser = (window as any)['CryptoJS'].TripleDES.encrypt( // 
                            JSON.stringify(newUser),
                            sessionUUID
                        ).toString();

                        setUserLS(encryptedUser);

                        onToastSuccess('Ingreso correcto', 'Bienvenido al Calendario de ocupación');
                        navigate('/');
                    }else{
                        console.log(responseU.message);
                        onToastError('Error', 'No se pudo obtener el Rol');
					}
                });
            }else{
                console.log(response.message);
                console.log(token);
                onToastError('Error', 'Usuario o contraseña invalidos');
            }
		});
	};

    const loggedUser = (): any => {
		let usuario = decrypt(getUserLS());
		if (usuario) 
            return JSON.parse(JSON.stringify(usuario));
		else 
            return {};
	};

    const rol = (): RolModel => {
		let usuario = decrypt(getUserLS());
		if (usuario) 
            return new RolModel(usuario.role);
		else 
            return {};
	};
    
    let value = {
		user,
		signin,
		signout,
        loggedUser,
        rol: rol()
	};
    
    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;