import qs from 'qs';
import { AxiosQuery } from "../api/AxiosQuery";
import { ReservacionFormModel, ReservacionParticipanteModel } from "../models/ReservacionModel";


export const getListaReservacionService = async() =>{
    let query = qs.stringify({ 
        fields: ['id', 'titulo', 'descripcion', 'fecha_inicio', 'fecha_fin'],
        populate: {
            res_espacio: {
                fields: ['id', 'nombre']
            },
            reservado_por:{
                fields: ['id', 'nombre', 'email']
            },
            reservado_para:{
                fields: ['id', 'nombre', 'email']
            },
            res_participantes:{
                populate:{
                    participante: {
                        fields: ['id', 'nombre', 'email']
                    }
                }
            }
        }
    },{
        encodeValuesOnly: true
    }); 
    
    return await AxiosQuery('GET', '/api/res-reservacions', query);
}

export const saveReservacionFormService = async(reservacionForm: ReservacionFormModel) =>{
    let query = qs.stringify({ 
        fields: ['id'],
    },{
        encodeValuesOnly: true
    }); 
    
    return await AxiosQuery('POST', '/api/res-reservacions', query, {data: reservacionForm});
}

export const saveReservacionParticipanteService = async(reservacionParticipante: ReservacionParticipanteModel) =>{
    let query = qs.stringify({ 
        fields: ['id'],
        populate: '*'
    },{
        encodeValuesOnly: true
    }); 
    return await AxiosQuery('POST', '/api/reservacion/participantes', query, reservacionParticipante);
} 

export const updateReservacionFormService = async(reservacionForm: ReservacionFormModel) =>{
    return await AxiosQuery('PUT', '/api/res-reservacions/' + reservacionForm.id, '', {data: reservacionForm});
}

export const removeReservacionService = async(id: number) =>{
    return await AxiosQuery('DELETE', '/api/res-reservacions/' + id, '');
}