export class EspacioModel{
    public id?: number;
    public nombre?: string;

    constructor(espacio: any){
        espacio = espacio || {
            id: null,
            nombre: ''
        }

        this.id = espacio.id;
        this.nombre = espacio.nombre;
    }
}