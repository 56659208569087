import axios from "axios";
import { getTokenLS } from "../helpers/LocalStorageIMJ";
import { EnviarEmailModel } from "../models/EnviarEmailModel";
import { ResponseModel } from "../models/ResponseModel";

export const AxiosEmail = async (enviarEmail: EnviarEmailModel) => {

    const request = {
        url: '/api/reservacion/confirmacion',
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: 'Bearer ' + JSON.parse(getTokenLS())
        },
        baseURL: process.env.REACT_APP_BASE_URL,
        data: enviarEmail
    };

    let responseModel: ResponseModel = new ResponseModel();

    try{
        const response = await axios.request(request);
        //responseModel.data = NormalizeData(response.data);
        //responseModel.data = response.data;
        responseModel.status = response.status;

        return responseModel;
    }catch(error: any){
        console.log('error');
        console.log(error)
        responseModel.message = error.message;
        responseModel.status = 404
        return responseModel;
    }
}