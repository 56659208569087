
let eventGuid = 0

// export const INITIAL_EVENTS: EventModel[] = [
//   {
//     id: createEventId(),
//     title: 'Reunión Reunión Reunión Reunión Reunión Reunión',
//     start: todayStr + 'T12:30:00',
//     end: todayStr + 'T13:00:00'
//   },
//   {
//     id: createEventId(),
//     title: 'Evento',
//     start: todayStr + 'T14:00:00',
//     end: todayStr + 'T16:00:00'
//   },
//   {
//     id: createEventId(),
//     title: 'Reunion de Director',
//     start: todayStr + 'T17:00:00',
//     end: todayStr + 'T18:00:00'
//   },
//   {
//     id: createEventId(),
//     title: 'Vamos a jugar 1',
//     start: todayStr + 'T18:00:00',
//     end: todayStr + 'T18:30:00'
//   },
//   {
//     id: createEventId(),
//     title: 'Vamos a jugar 2',
//     start: todayStr + 'T20:00:00',
//     end: todayStr + 'T21:30:00'
//   },
//   {
//     id: createEventId(),
//     title: 'Vamos a jugar 3',
//     start: todayStr + 'T22:00:00',
//     end: todayStr + 'T23:00:00'
//   }
//   {
//     id: createEventId(),
//     title: 'Reunión Reunión Reunión Reunión Reunión Reunión',
//     start: '2023-02-22T12:30:00',
//     end: '2023-02-22T13:00:00',
//     description: 'Jorge',
//     area: 1
//   },
//   {
//     id: createEventId(),
//     title: 'Evento',
//     start: '2023-02-22T14:00:00',
//     end: '2023-02-22T16:00:00',
//     description: 'Juan',
//     area: 2
//   },
//   {
//     id: createEventId(),
//     title: 'Reunion de Director',
//     start: '2023-02-22T17:00:00',
//     end: '2023-02-22T18:00:00',
//     description: 'Juan',
//     area: 3
//   },
//   {
//     id: createEventId(),
//     title: 'Vamos a jugar 1',
//     start: '2023-02-22T18:00:00',
//     end: '2023-02-22T18:30:00',
//     description: 'Adan',
//     area: 1
//   },
//   {
//     id: createEventId(),
//     title: 'Vamos a jugar 2',
//     start: '2023-02-22T20:00:00',
//     end: '2023-02-22T21:30:00',
//     description: 'Olaf',
//     area: 2
//   },
//   {
//     id: createEventId(),
//     title: 'Vamos a jugar 3',
//     start: '2023-02-22T22:00:00',
//     end: '2023-02-22T23:00:00',
//     description: 'Denise',
//     area: 1
//   }
//   ,
//   {
//     id: createEventId(),
//     title: 'renuion Lunes',
//     start: '2023-02-20T09:00:00',
//     end: '2023-02-20T10:00:00',
//     description: 'Rene',
//     area: 2
//   }
//   ,
//   {
//     id: createEventId(),
//     title: 'renuion Lunes 2',
//     start: '2023-02-20T13:00:00',
//     end: '2023-02-20T14:00:00',
//     description: 'Jorge',
//     area: 1
//   },
//   {
//     id: createEventId(),
//     title: 'renuion Lunes 3',
//     start: '2023-02-20T16:00:00',
//     end: '2023-02-20T18:30:00',
//     description: 'Juan',
//     area: 3
//   },
//   {
//     id: createEventId(),
//     title: 'renuion Martes',
//     start: '2023-02-21T09:00:00',
//     end: '2023-02-21T10:00:00',
//     description: 'Jorge',
//     area: 4
//   }
// ]

export function createEventId() {
  return String(eventGuid++)
}

export class EventModel{
    public id?: string;
    public title?: string;
    public start?: string;
    public end?: string;
    public backgroundColor?: string = 'white';
    public borderColor?: string = 'white';
    public extendedProps?: any;
}

