import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { classNames } from 'primereact/utils';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAuth } from '../../../auth/AuthContext';
import { AreaTrabajoSelectModel } from '../../../models/AreaTrabajoModel';
import { EspacioModel } from '../../../models/EspacioModel';
import { ReservacionFormModel } from '../../../models/ReservacionModel';
import { UsuarioModel } from '../../../models/UsuarioModel';
import './FormDialog.scss';

interface props{
  visibleFormDialog: boolean,
  setVisibleFormDialog: any,
  isInfo: boolean,
  reservacion: ReservacionFormModel | undefined,
  onClickGuardaReservacion: any,
  onConfirmRemove: any,
  listaEspacio?: EspacioModel[],
  listaAreaTrabajoSelect?: AreaTrabajoSelectModel[];
}

const FormDialog = (props: props) =>{
  // Config
  const timeInicioMin: string = '09:00:00',
  timeInicioMax: string = '18:00:00',
  timeFinMin: string = '09:30:00',
  timeFinMax: string = '18:30:00';

  const { rol, loggedUser } = useAuth();

  const reservacionForm: {[key: string]: any} = {
    id: props.reservacion?.id,
    res_espacio: props.reservacion?.res_espacio,
    titulo: props.reservacion?.titulo,
    descripcion: props.reservacion?.descripcion,
    fecha_inicio: props.reservacion?.fecha_inicio,
    fecha_fin: props.reservacion?.fecha_fin,
    reservado_por: props.reservacion?.reservado_por,
    reservado_para: props.reservacion?.reservado_para,
    res_participantes: props.reservacion?.res_participantes
  }

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isPropio, setIsPropio] = useState<boolean>(false);
  const { control, formState: { errors }, handleSubmit, reset, setValue, getValues } = useForm<ReservacionFormModel | any>();
  const [fechaOnly, setFechaOnly] = useState<any>(null);
  const [fechaFinMin, setFechaFinMin] = useState<any>(null);
  const [fechaFinMax, setFechaFinMax] = useState<any>(null);
  const [isDateEnd, setIsDataEnd] = useState<boolean>(false);

  useEffect(() => {
    setIsEdit(false);
    
    reset(reservacionForm);

    setFechaOnly(getDateOnlyWithString(reservacionForm.fecha_inicio));

    setFechaFinMin(moment(getDateOnlyWithString(reservacionForm.fecha_fin) + 'T' + timeFinMin).toDate());
    setFechaFinMax(moment(getDateOnlyWithString(reservacionForm.fecha_fin) + 'T' + timeFinMax).toDate());
  
    // Verificar si es admin o demas
    OnVerificar();
    // eslint-disable-next-line
  }, [props.visibleFormDialog])

  const getDateOnlyWithString = (date: any): string =>{
    return moment(date).format('YYYY-MM-DD').toString();
  }

  const getFormErrorMessage = (field: string) => {
    return errors[field] && <small className="p-error">{ errors[field]?.message?.toString() }</small>
  };

  const onEliminaReservacion = () => {
    confirmDialog({
      message: '¿Deseas eliminar la reservación?',
      header: '',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => props.onConfirmRemove(reservacionForm.id),
      acceptLabel: 'Aceptar',
      rejectLabel: 'Cancelar'
    });
  };

  const OnVerificar = (): void =>{
    const user: UsuarioModel = loggedUser();
    //const rol: RolModel = new RolModel(rol());
    // Reservado Por o Admin/manager para que puede editar
    const _isPropio: boolean = props.reservacion?.reservado_por?.id === user.id || (rol.type === 'administrador' || rol.type === 'management');
    setIsPropio(_isPropio);
    
    // Si reservado para o participantes para que puede ver la informacion de la reservación
    const _isVisible: boolean | undefined = _isPropio || props.reservacion?.reservado_para?.id === user.id || props.reservacion?.res_participantes?.some(r => r.id === user.id);
    setIsVisible(_isVisible as any);

    const _isDateEnd: boolean = moment().format('x') >= moment(reservacionForm.fecha_fin).format('x');
    setIsDataEnd(_isDateEnd);
  }

  const onChangeFechaInicio = (event: CalendarChangeEvent): void => {
    // Establecer el valor a la fecha inicio
    if(event.value){
      setValue('fecha_inicio', event.value);

      // Si cambia la fecha
      if(moment(event.value as any).format('L') !== moment(fechaOnly).format('L')){
        setValue('fecha_fin', moment(event.value as any).add(30, 'minutes').toDate());
        setFechaFinMin(moment(getDateOnlyWithString(event.value) + 'T' + timeFinMin).toDate());
        setFechaFinMax(moment(getDateOnlyWithString(event.value) + 'T' + timeFinMax).toDate());
        setFechaOnly(getDateOnlyWithString(event.value));
        return;
      }

      // Fecha inicio maximo
      if(parseInt(moment(event.value as any).format('HHmm')) >= parseInt(timeFinMax.replace(':', ''))){
        setValue('fecha_inicio', moment(getDateOnlyWithString(event.value) + 'T' + timeInicioMax).toDate());
        return;
      }

      // Fecha inicio maximo
      if(parseInt(moment(event.value as any).format('HHmm')) <= parseInt(timeInicioMin.replace(':', ''))){
        setValue('fecha_inicio', moment(getDateOnlyWithString(event.value) + 'T' + timeInicioMin).toDate());
        return;
      }

      // La fecha inicio es mayor que fecha menor para cambiarlo
      if(moment(event.value as any).format('x') >= moment(getValues('fecha_fin') as any).format('x')){
        setValue('fecha_fin', moment(event.value as any).add(30, 'minutes').toDate());
        return;
      }
    }
  }

  const onChangeFechaFin = (event: CalendarChangeEvent) => {
    if(event.value){
      setValue('fecha_fin', event.value);

      // La fecha fin es menor que fecha inicio para cambiarlo
      if(moment(event.value as any).format('x') <= moment(getValues('fecha_inicio') as any).format('x')){
        setValue('fecha_inicio', moment(event.value as any).add(-30, 'minutes').toDate());
      }
    }
  }

  return (
    <>
      <ConfirmDialog />
      <Dialog visible={props.visibleFormDialog} style={{ width: '20vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} onHide={() => props.setVisibleFormDialog(false)}>
        <div className='card'>
          <h2>{props.isInfo && !isEdit ? 'Información' : isEdit ? 'Editar ' : 'Crear '} la reservación</h2>
          <form onSubmit={handleSubmit(props.onClickGuardaReservacion)} className="p-fluid">
            {/* Id Espacio (Area) */}
            <div className="field">
              <span className="p-float-label">
                <Controller name="res_espacio" control={control} rules={{ required: 'Area es requierda.' }} render={({ field, fieldState }) => (
                  <Dropdown {...field} disabled={props.isInfo && !isEdit} autoFocus options={props.listaEspacio} optionValue='id' optionLabel='nombre' filter showClear filterBy='nombre' className={classNames({ 'p-invalid': fieldState.invalid })} />
                )} />
                <label htmlFor="res_espacio" className={classNames({ 'p-error': errors.res_espacio })}>Area*</label>
              </span>
              {getFormErrorMessage('res_espacio')}
            </div>
            {/* Titulo */}
            <div className="field">
              <span className="p-float-label">
                <Controller name="titulo" control={control} rules={{ required: 'Titulo es requierdo.', minLength: {value: 2, message: 'El minimo de 2 caraacteres.'} }} render={({ field, fieldState }) => (
                  <InputText id={field.name} disabled={props.isInfo && !isEdit} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                )} />
                <label htmlFor="titulo" className={classNames({ 'p-error': errors.titulo })}>Titulo*</label>
              </span>
              {getFormErrorMessage('titulo')}
            </div>
            {/* Descripción */}
            <div className="field" style={!isVisible ? {display: 'none'} : {}}>
              <span className="p-float-label">
                <Controller name="descripcion" control={control} render={({ field, fieldState }) => (
                  <InputTextarea id={field.name} {...field} disabled={props.isInfo && !isEdit} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                )} />
                <label htmlFor="descripcion" className={classNames({ 'p-error': errors.descripcion })}>Descripción</label>
              </span>
              {getFormErrorMessage('descripcion')}
            </div>
            {/* Reservado por */}
            <div className="field" style={!isVisible ? {display: 'none'} : {}}>
              <span className="p-float-label">
                <Controller name="reservado_por" control={control}  rules={{ required: 'Este campo es requierdo.' }} render={({ field, fieldState }) => (
                  <Dropdown id={field.name} {...field} filter filterBy='nombre' disabled={true} options={props.listaAreaTrabajoSelect} optionLabel="nombre" optionGroupLabel="nombre" optionGroupChildren="usuarios"  />
                )} />
                <label htmlFor="reservado_por" className={classNames({ 'p-error': errors.reservado_por })}>Reservado por*</label>
              </span>
              {getFormErrorMessage('reservado_por')}
            </div>
            {/* Reservado para */}
            <div className="field" style={!isVisible ? {display: 'none'} : {}}>
              <span className="p-float-label">
                <Controller name="reservado_para" control={control}  rules={{ required: 'Este campo es requierdo.' }} render={({ field, fieldState }) => (
                  <Dropdown id={field.name} {...field} filter filterBy='nombre' disabled={props.isInfo && !isEdit} options={props.listaAreaTrabajoSelect} optionLabel="nombre" optionGroupLabel="nombre" optionGroupChildren="usuarios"  />
                )} />
                <label htmlFor="reservado_para" className={classNames({ 'p-error': errors.reservado_para })}>Reservado para*</label>
              </span>
              {getFormErrorMessage('reservado_para')}
            </div>
            {/* Participantes */}
            <div className="field">
              <span className="p-float-label" style={!isVisible ? {display: 'none'} : {}}>
                <Controller name="res_participantes" control={control}  rules={{ required: 'Este campo es requierdo.' }} render={({ field, fieldState }) => (
                  <MultiSelect id={field.name} {...field} filter filterBy='nombre' disabled={props.isInfo && !isEdit} maxSelectedLabels={3} options={props.listaAreaTrabajoSelect} optionLabel="nombre" optionGroupLabel="nombre" optionGroupChildren="usuarios" />
                )} />
                <label htmlFor="res_participantes" className={classNames({ 'p-error': errors.res_participantes })}>Participantes*</label>
              </span>
              {getFormErrorMessage('res_participantes')}
            </div>
            {/* Fecha Inicio */}
            <div className="field">
              <span className="p-float-label">
                <Controller name="fecha_inicio" control={control} rules={{ required: 'Fecha inicio es requierda.' }} render={({ field, fieldState }) => (
                  <Calendar id={field.name} disabled={props.isInfo && !isEdit} showTime {...field} className={classNames({ 'p-invalid': fieldState.invalid })} dateFormat="d 'de' MM 'de' yy" minDate={moment().add(-12, 'hours').toDate()} onChange={(e) => onChangeFechaInicio(e)} />
                )} />
                <label htmlFor="fecha_inicio" className={classNames({ 'p-error': errors.fecha_inicio })}>Fecha inicio*</label>
              </span>
              {getFormErrorMessage('fecha_inicio')}
            </div>
            {/* Fecha Fin */}
            <div className="field">
              <span className="p-float-label">
                <Controller name="fecha_fin" control={control} rules={{ required: 'Fecha fin es requierda.' }} render={({ field, fieldState }) => (
                  <Calendar id={field.name} disabled={props.isInfo && !isEdit} showTime {...field} className={classNames({ 'p-invalid': fieldState.invalid })} onChange={(e) => onChangeFechaFin(e)} dateFormat="d 'de' MM 'de' yy" minDate={fechaFinMin} maxDate={fechaFinMax} />
                )} />
                <label htmlFor="fecha_fin" className={classNames({ 'p-error': errors.fecha_fin })}>Fecha fin*</label>
              </span>
              {getFormErrorMessage('fecha_fin')}
            </div>
            <div style={isPropio && isVisible ? {} : {display: 'none'}}>
              <div className='flex flex-row' >
                {props.isInfo || isEdit ? <Button type="button" label='Eliminar' onClick={onEliminaReservacion} /> : null}
                <Button type="button" label={props.isInfo || isEdit ? 'Cerrar' : 'Cancelar'} className={props.isInfo || isEdit ? !isDateEnd ? 'mx-2' : 'ml-2' : 'mr-2'} onClick={() => props.setVisibleFormDialog(false)} />
                {props.isInfo && !isEdit && !isDateEnd ? <Button type='button' label='Editar' onClick={() => setIsEdit(true)} /> : null}
                {isEdit ? <Button type="submit" label='Guardar' /> : !props.isInfo && !isEdit ? <Button type="submit" label='Crear' /> : null}
              </div>
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
};

export default FormDialog;