import { AxiosQuery } from "../api/AxiosQuery";
import qs from 'qs';
import { LoginParam } from "../models/LoginModel";

export const getLoginTokenService = async(loginData: LoginParam): Promise<any> => {
    let query = qs.stringify({ 
        fields: ['jwt'],
	    populate: {
            user: {
                fields: ['id', 'username', 'email'],
                populate: { role: true },
            },
	    },
    },{
        encodeValuesOnly: true
    }); 
    return await AxiosQuery('POST', '/api/auth/local', query, loginData);
}