import { ProgressSpinner } from 'primereact/progressspinner';
import './Loader.scss';

export const Loader = (props: any) => {
    return (
        <>
            <div className="loader">
                <ProgressSpinner />
            </div>
        </>
    )
}