import qs from 'qs';
import { AxiosQuery } from "../api/AxiosQuery";
import { ResponseModel } from "../models/ResponseModel";
import { UsuarioModel } from "../models/UsuarioModel";

export const getUsuarioService = async(id: number): Promise<any> => {
    let query = qs.stringify({ 
        populate: '*',
    }); 

    return await AxiosQuery('GET', '/api/users/' + id, query, '');
}

export const getListaUsuarioService = async(): Promise<any> => {
    let query = qs.stringify({ 
        fields: ['id', 'nombre', 'email'],
    },{
        encodeValuesOnly: true
    }); 
    return await AxiosQuery('GET', '/api/users', query, '').then((response: ResponseModel) => {
        if(response.status === 200){
            response.data = response.data.map((usuario: any) => { return new UsuarioModel(usuario); });
        }
        
        return response;
    });
}