import { DateSelectArg, DayHeaderContentArg, EventChangeArg, EventClickArg, EventContentArg } from '@fullcalendar/core';
import { createRef } from '@fullcalendar/core/preact';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment';
import { BlockUI } from 'primereact/blockui';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../auth/AuthContext';
import Card from '../../components/card/Card';
import FormDialog from '../../components/dialogs/form/FormDialog';
import { Loader } from '../../components/loader/Loader';
import { useToast } from '../../components/toast/Toast';
import { AreaTrabajoSelectModel } from '../../models/AreaTrabajoModel';
import { EnviarEmailModel } from '../../models/EnviarEmailModel';
import { EspacioModel } from '../../models/EspacioModel';
import { EventModel } from '../../models/EventModel';
import { ParticipanteModel } from '../../models/ParticipanteModel';
import { ReservacionFormModel, ReservacionModel, ReservacionParticipanteModel, createEventId } from '../../models/ReservacionModel';
import { ResponseModel } from '../../models/ResponseModel';
import { UsuarioModel } from '../../models/UsuarioModel';
import { getListaAreaTrabajoSelectService } from '../../services/AreaTrabajoService';
import { EnviarEmailService } from '../../services/EmailService';
import { getListaEspacioService } from '../../services/EspacioService';
import { removeParticipanteService, saveParticipanteService } from '../../services/ParticipanteService';
import { getListaReservacionService, removeReservacionService, saveReservacionFormService, saveReservacionParticipanteService, updateReservacionFormService } from '../../services/ReservacionService';
import { getListaUsuarioService } from '../../services/UsuarioService';
import './CalendarioDeOcupacion.scss';

moment.locale('es-mx');

const CalendarioDeOcupacion = () => {
    // Calendar
    const calendarComponentRef = createRef();
    // Lista de Reservacion
    let [listaReservacion, setListaReservacion] = useState<ReservacionModel[]>([]);
    // Espacios
    const [listaEspacio, setListaEspacio] = useState<EspacioModel[]>([]);
    // Lista de Ususario
    const [listaUsuario, setListaUsuario] = useState<UsuarioModel[]>([]);
    // Lista de Area Trabajo Select
    const [listaAreaTrabajoSelect, setListaAreaTrabajoSelect] = useState<AreaTrabajoSelectModel[]>();
    // Lista de Evento para el calendario
    const [listaEvent, setListaEvent] = useState<EventModel[]>([]);
    const [visibleFormDialog, setVisibleFormDialog] = useState(false);
    const [isInfo, setIsInfo] = useState(false);
    const [reservacionForm, setReservacionForm] = useState<ReservacionFormModel>({});
    const [isLoader, setIsLoader] = useState(false);
    //const [isPropio, setIsPropio] = useState<boolean>(false);

    const { onToastSuccess, onToastError } = useToast();
    //const [calendarApi, setCalendarApi] = useState<CalendarApi>();

    const { loggedUser, rol } = useAuth();

    let lis: any = [];

    // get lista de Reservacion
    const getListaReservacion = async() =>{
        await getListaReservacionService().then((response: ResponseModel) => {
            if(response.status === 200){
                const _listaReservacion: ReservacionModel[] | undefined = [...response.data];
                setListaReservacion(_listaReservacion);
                
                // Asignamos los datos para lista de evento
                let _listaEvento: EventModel[] = [];
                _listaReservacion.forEach(reservacion => {
                    // Agregamos el evento de calendario
                    let eventModel: EventModel = new EventModel();
                    eventModel.id = reservacion.id?.toString();
                    eventModel.title = reservacion.titulo;
                    eventModel.start = String(reservacion.fecha_inicio);
                    eventModel.end = String(reservacion.fecha_fin);
                    //eventModel.backgroundColor = 'white';
                    //eventModel.borderColor = listaAreaTrabajoSelect?.find(at => {return at.usuarios?.find(u => u.id === reservacion.reservado_para?.id)})?.color;
                    eventModel.extendedProps = { 
                        colorArea: listaAreaTrabajoSelect?.find(at => {return at.usuarios?.find(u => u.id === reservacion.reservado_para?.id)})?.color,
                        espacioId: reservacion.res_espacio?.id 
                    };

                    _listaEvento.push(eventModel);
                    lis.push(reservacion);
                });
                setListaEvent(_listaEvento);


            }else{
                console.log(response.message);
                onToastError('Error', 'No se puede obtener lista del espacio, intente mas tarde.');
            }
        });
    }

    // get Lista de Espacio
    const getListaEspacio = async() =>{
        await getListaEspacioService().then((response: ResponseModel) => {
            if(response.status === 200){
                setListaEspacio(response.data);
            }else{
                console.log(response.message);
                onToastError('Error', 'No se puede obtener lista del espacio, intente mas tarde.');
            }
        });
    }

    // get Lista de Usuario 
    const getListaUsuario = async() =>{
        await getListaUsuarioService().then((response: ResponseModel) => {
            if(response.status === 200){
                setListaUsuario(response.data);
            }else{
                console.log(response.message);
                console.log(listaUsuario)
                onToastError('Error', 'No se puede obtener lista del Usuario, intente mas tarde.');
            }
        });
    }

    // get Lista de Area Trabajo Select 
    const getListaAreaTrabajoSelect = async() =>{
        await getListaAreaTrabajoSelectService().then((response: ResponseModel) => {
            if(response.status === 200){
                setListaAreaTrabajoSelect(response.data);
            }else{
                console.log(response.message);
                onToastError('Error', 'No se puede obtener lista del Area Trabajo, intente mas tarde.');
            }
        });
    }

    // Listas
    useEffect(() => {
        //getListaReservacion();
        getListaEspacio();
        getListaUsuario();
        getListaAreaTrabajoSelect();
        // eslint-disable-next-line
    }, [])
  
    // Cuando terminar carga de lista de Area Trabajo para que se cargue lista de reservacion.
    useEffect(() => {
        if(listaAreaTrabajoSelect){
            getListaReservacion();
        }
        // eslint-disable-next-line
    }, [listaAreaTrabajoSelect]);

    // Ciclo de vida (lista de reservación)
    // Para saber si hay cambio o nueva o eliminar para actualizarlo
    useEffect(() => {
        // Cada 10 segundos (Ciclo de vida)
        const interval = setInterval(async() => {
            await lifecycleListaReservacion();
        }, 10000);
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, [listaReservacion]);

    const lifecycleListaReservacion = async() =>{
        await getListaReservacionService().then((response: ResponseModel) => {
            if(response.status === 200){
                const _listaReservacion: ReservacionModel[] = [...response.data];
                
                // Obtenemos el ID de las reservaciones
                const listaReservacionId: any[] = Array.from(listaReservacion.map(r => r.id)) as any;

                // Obtenemos si reservacion es nueva para agregar en lista de reservacion
                const nuevaListaReservacion = _listaReservacion.filter(r => !listaReservacionId.includes(r.id));
                if(nuevaListaReservacion.length > 0){
                    setListaReservacion(r => [...r,...nuevaListaReservacion]);

                    nuevaListaReservacion.forEach(reservacion => {
                        // Agregamos el evento de calendario
                        let nuevoEventModel: EventModel = new EventModel();
                        nuevoEventModel.id = reservacion.id?.toString();
                        nuevoEventModel.title = reservacion.titulo;
                        nuevoEventModel.start = String(reservacion.fecha_inicio);
                        nuevoEventModel.end = String(reservacion.fecha_fin);
                        nuevoEventModel.extendedProps = { 
                            colorArea: listaAreaTrabajoSelect?.find(at => {return at.usuarios?.find(u => u.id === reservacion.reservado_para?.id)})?.color,
                            espacioId: reservacion.res_espacio?.id 
                        };

                        setListaEvent(e => [...e, nuevoEventModel]);
                    });
                }

                // Obtenemos si la reservacion es borrado o no existe para eliminarlo
                const eliminaListaReservacion = listaReservacion.filter(r => !_listaReservacion.map(_r => {return _r.id}).includes(r.id));
                if(eliminaListaReservacion.length > 0){
                    // Eliminamos la reservacion
                    let updateListaReservacion = [...listaReservacion].filter(r => !eliminaListaReservacion.map(_r => {return _r.id}).includes(r.id));
                    setListaReservacion(updateListaReservacion);
                    // Eliminamos la reservacion
                    let updateListaEvent = [...listaEvent].filter(e => !eliminaListaReservacion.map(_r => {return _r.id?.toString()}).includes(e.id));
                    setListaEvent(updateListaEvent);
                }

                // Obtener si hay cambio de la reservacion para agregar en lista de reservacion como orden
                //const actualizaListaReservacion = _listaReservacion.filter(r => listaReservacion.map(_r => r.titulo !== _r.titulo || r.descripcion !== _r.descripcion || r.reservado_para !== _r.reservado_para || r.res_participantes !== _r.res_participantes || r.fecha_inicio !== _r.fecha_inicio || r.fecha_fin !== _r.fecha_fin));
                const actualizaListaReservacion = _listaReservacion.filter(r => listaReservacion.find(_r => r.id === _r.id && (r.titulo !== _r.titulo || r.descripcion !== _r.descripcion || r.reservado_para?.id !== _r.reservado_para?.id || JSON.stringify(r.res_participantes) !== JSON.stringify(_r.res_participantes) || moment(r.fecha_inicio).format('x') !== moment(_r.fecha_inicio).format('x') || moment(r.fecha_fin).format('x') !== moment(_r.fecha_fin).format('x'))));
                if(actualizaListaReservacion.length > 0){
                    // Obtenemos lista de reservacion
                    let  _actualizaListaReservacion: ReservacionModel[] = [...listaReservacion];
                    // Obtenemos lista de evento
                    let actualizaListaEvent: EventModel[] = [...listaEvent];
                    actualizaListaReservacion.forEach(r => {
                        // Actualizamos la reservacion en lista
                        let indexReservacion: number = listaReservacion.findIndex(_r => _r.id === r.id),
                        actualizaReservacion = {..._actualizaListaReservacion[indexReservacion]};

                        // Actualizamos la reservacion (obtener y establecer los datos del formulario)
                        actualizaReservacion.res_espacio = listaEspacio.find(espacio => espacio.id === r.res_espacio?.id);
                        actualizaReservacion.titulo = r.titulo;
                        actualizaReservacion.descripcion = r.descripcion;
                        actualizaReservacion.reservado_para = r.reservado_para;
                        actualizaReservacion.reservado_por = r.reservado_por;
                        actualizaReservacion.res_participantes = r.res_participantes;
                        actualizaReservacion.fecha_inicio = r.fecha_inicio;
                        actualizaReservacion.fecha_fin = r.fecha_fin;

                        _actualizaListaReservacion[indexReservacion] = {...actualizaReservacion};
                        setListaReservacion([..._actualizaListaReservacion]);

                        // Actualizamos el evento para el calendario
                        let indexEvent = actualizaListaEvent.findIndex(e => e.id === r.id?.toString()),
                        actualizaEventModel: EventModel = {...actualizaListaEvent[indexEvent]};
                        actualizaEventModel.id = r.id?.toString();
                        actualizaEventModel.title = r.titulo;
                        actualizaEventModel.start = r.fecha_inicio as any;
                        actualizaEventModel.end = r.fecha_fin as any;
                        actualizaEventModel.extendedProps = { 
                            colorArea: listaAreaTrabajoSelect?.find(at => {return at.usuarios?.find(u => u.id === actualizaReservacion.reservado_para?.id)})?.color, 
                            espacioId: actualizaReservacion.res_espacio?.id 
                        };

                        // Actualizamos el evento para calendario
                        actualizaListaEvent[indexEvent] = {...actualizaEventModel};
                        setListaEvent([...actualizaListaEvent]);
                    });
                    
                }
            }
        });
    }
   
    const onSelect = (selectInfo: DateSelectArg) => {
  
      if(selectInfo.view.type !== 'dayGridMonth'){
            const fechaStart: string = moment(selectInfo.start).format('L'),
            fechaEnd: string = moment(selectInfo.end).format('L');
            //_listaEvent = listaEvent.filter(e => moment(e.start).format('L') === fechaStart);

            let calendarApi = selectInfo.view.calendar;

            // Verificar si la fecha es terminado
            if(onVerificarTerminaFecha(selectInfo.end)){
                calendarApi?.unselect();
                return;
            }
            
            // No se puede crear o cambiar de la fecha antes de la fecha actual
            if(onVerificarFechaAnterior(selectInfo.start)){
                calendarApi?.unselect();
                onToastError('Error', 'No se puede ingresar una fecha anterior a la actual.');
                return;
            }
            
            //setCalendarApi(_calendarApi);
            //calendarApi.unselect();
            if(fechaStart === fechaEnd){
            
                // _listaEvent.forEach(e => {
                //     console.log((moment(selectInfo.start).format('x') >= moment(e.start).format('x') && moment(selectInfo.start).format('x') < moment(e.end).format('x')) || (moment(selectInfo.end).format('x') > moment(e.start).format('x') && moment(selectInfo.end).format('x') <= moment(e.end).format('x')) || (moment(selectInfo.start).format('x') < moment(e.start).format('x') && moment(selectInfo.end).format('x') > moment(e.end).format('x')));
                // });
            
                // La validacion: si hay las reservaciones existentes u ocupadas y no se puede crear
                // const esExisteReservacion: boolean = _listaEvent.some(e => 
                //     (moment(selectInfo.start).format('x') >= moment(e.start).format('x') && moment(selectInfo.start).format('x') < moment(e.end).format('x')) ||
                //     (moment(selectInfo.end).format('x') > moment(e.start).format('x') && moment(selectInfo.end).format('x') <= moment(e.end).format('x')) || 
                //     (moment(selectInfo.start).format('x') < moment(e.start).format('x') && moment(selectInfo.end).format('x') > moment(e.end).format('x')));
            
                //if(onVerificarFechas(selectInfo.start, selectInfo.end)){
                    //calendarApi.unselect();
                    //calendarApi?.unselect();
                    //return;
                //}
        
                let reservacion: ReservacionModel = new ReservacionModel();
                reservacion.id = createEventId();
                reservacion.res_espacio = null as any;
                reservacion.fecha_inicio = selectInfo.start;
                reservacion.fecha_fin = selectInfo.end;
                reservacion.reservado_por = new UsuarioModel(loggedUser());
    
                setReservacionForm(reservacion as any);
                setIsInfo(false);
                setVisibleFormDialog(true);
            }else{
                calendarApi?.unselect();
                return;
            }
        }
    }
  
    const onEventContent = (event: EventContentArg) => {
        return (
            <Card event={event} />
        )
    }
  
    const onEventClick = (info: EventClickArg) =>{
        //console.log('click')
        const reservacion: ReservacionModel | undefined = listaReservacion.find(r => r.id === parseInt(info.event.id));

        let reservacionForm: ReservacionFormModel = {...(reservacion as any)};
        reservacionForm.res_espacio = reservacion?.res_espacio?.id;
        reservacionForm.res_participantes = reservacion?.res_participantes?.map(participante => {return participante.participante}) as [];
        reservacionForm.fecha_inicio = new Date(reservacion?.fecha_inicio as any);
        reservacionForm.fecha_fin = new Date(reservacion?.fecha_fin as any);

        setReservacionForm(reservacionForm);
        setIsInfo(true);
        setVisibleFormDialog(true);
    }

    const onEventChange = (event: EventChangeArg) => {
        if(event){
            // Mostramos Loader
            setIsLoader(true);

            // Verificar si la fecha es terminado
            if(onVerificarTerminaFecha(event.event.end)){
                // Ocultamos Loader
                setIsLoader(false);
                // Regresa su lugar de evento
                onRegresaEvent(event.event.id, event.oldEvent.startStr, event.oldEvent.endStr);
                return;
            }

            // Verificar si las fechas y espacio son la misma no se puede aplicar
            if(onVerificarFechas(event.event.start, event.event.end, event.event.id, event.event.extendedProps.espacioId)){
                // Ocultamos Loader
                setIsLoader(false);
                // Regresa su lugar de evento
                onRegresaEvent(event.event.id, event.oldEvent.startStr, event.oldEvent.endStr);
                return;
            }

            const reservacion: ReservacionModel = {...listaReservacion.find(r => r.id === parseInt(event.event.id))};

            // Verificar si el evento es propio para que puede mover o editar
            if(onVerificarEsPropio(reservacion)){
                // No se puede crear o cambiar de la fecha antes de la fecha actual
                if(onVerificarFechaAnterior(event.event.start)){
                    // Ocultamos Loader
                    setIsLoader(false);

                    onToastError('Error', 'No se puede ingresar una fecha anterior a la actual.');
                    onRegresaEvent(event.event.id, event.oldEvent.startStr, event.oldEvent.endStr);
                    return;
                }

                let reservacionForm: ReservacionFormModel = reservacion as any;
                reservacionForm.res_espacio = reservacion.res_espacio?.id;
                reservacionForm.fecha_inicio = event.event.start;
                reservacionForm.fecha_fin = event.event.end;

                updateReservacionFormService(reservacionForm).then((response: ResponseModel) => {
                    if(response.status === 200){
                        onToastSuccess('Correcto', 'Ha actualizado la reservación.');

                        let enviarEmail: EnviarEmailModel = new EnviarEmailModel(reservacionForm);
                        enviarEmail.res_espacio = listaEspacio.find(espacio => espacio.id === reservacionForm.res_espacio);

                        // Enviar email para la invitación
                        let actualizaEnviarEmail: EnviarEmailModel = new EnviarEmailModel(enviarEmail);
                        actualizaEnviarEmail.method = 'REQUEST'; 
                        
                        onEnviarEmail(actualizaEnviarEmail);
                        //setIsLoader(false);
                    }else{
                        // Ocultamos Loader
                        setIsLoader(false);

                        console.log(response.message);
                        onToastError('Error', 'No se puede guardar los participantes, intente mas tarde.');
                    }
                    
                });
            }else{
                // Regresa su lugar de evento
                onRegresaEvent(event.event.id, event.oldEvent.startStr, event.oldEvent.endStr);
            }
        }
    }

    const onRegresaEvent = (id: string, start: string, end: string): void =>{
        // Actualizamos el evento para el calendario
        let actualizaListaEvent: EventModel[] = [...listaEvent],
        indexEvent = listaEvent.findIndex(e => e.id === id);
        let actualizaEventModel: EventModel = {...actualizaListaEvent[indexEvent]};
        actualizaEventModel.start = start;
        actualizaEventModel.end = end;

        // Actualizamos el evento para calendario
        actualizaListaEvent[indexEvent] = {...actualizaEventModel};
        setListaEvent(actualizaListaEvent);
    }
  
    const onClickGuardaReservacion = (reservacionForm: ReservacionFormModel): void => {

        // Verificar si la fecha es terminado
        if(onVerificarTerminaFecha(reservacionForm.fecha_fin)){
            onToastError('Error', 'No se puede ingresar una fecha anterior a la actual.');
            return;
        }

        if(onVerificarFechas(reservacionForm.fecha_inicio, reservacionForm.fecha_fin, reservacionForm.id as any, reservacionForm.res_espacio as any)){
            // Mostramos Loader
            setIsLoader(false);
            onToastError('Error', 'El espacio se encuentro ocupado, eliges otro por favor.');
            return;
        }

        // Mostramos Loader
        setIsLoader(true);
    
        if(isInfo){
            const listaParticipanteUsuario: UsuarioModel[] = [...(reservacionForm.res_participantes as any)];

            //Eliminamos los objetos de la reservacion para poder actualizarlo
            delete reservacionForm.res_participantes;

            updateReservacionFormService(reservacionForm).then((response: ResponseModel) => {
                if(response.status === 200){
                    onToastSuccess('Correcto', 'Ha actualizado la reservación.');

                    // Actualizamos la reservacion en lista
                    let actualizaListaReservacion: ReservacionModel[] = [...listaReservacion],
                    indexReservacion = listaReservacion.findIndex(r => r.id === reservacionForm.id),
                    actualizaReservacion = {...actualizaListaReservacion[indexReservacion]};

                    // Verificar los participantes son nuevos para guardar
                    listaParticipanteUsuario?.forEach(participante => {
                        if(!actualizaReservacion.res_participantes?.some(_participante => _participante.participante?.id === participante.id)){
                            let nuevoParticipante: ParticipanteModel = new ParticipanteModel();
                            delete nuevoParticipante.id
                            nuevoParticipante.participante = participante;
                            nuevoParticipante.res_reservacion = actualizaReservacion;

                            // Enviar api para guardar (nuevo participante);
                            saveParticipanteService(nuevoParticipante).then((responseRP: ResponseModel) => {
                                if(responseRP.status === 200){
                                    let _nuevoParticipante: ParticipanteModel = new ParticipanteModel(nuevoParticipante);
                                    _nuevoParticipante.id = responseRP.data.id;

                                    actualizaReservacion.res_participantes?.push(_nuevoParticipante)
                                }
                            });
                        }
                    });

                    // Verificar los participantes son que no estan para eliminar
                    actualizaReservacion.res_participantes?.forEach(participante => {
                        if(!listaParticipanteUsuario?.some(_participante => _participante.id === participante.participante?.id)){
                            // Enviar api para eliminar
                            removeParticipanteService(participante.id as any)

                            // Actualizamos la reservacion que estaba eliminado de participante
                            actualizaReservacion.res_participantes = actualizaReservacion.res_participantes?.filter(p => p.id !== participante.id);
                        }
                    });

                    // Actualizamos la reservacion (obtener y establecer los datos del formulario)
                    actualizaReservacion.res_espacio = listaEspacio.find(espacio => espacio.id === reservacionForm.res_espacio);
                    actualizaReservacion.titulo = reservacionForm.titulo;
                    actualizaReservacion.descripcion = reservacionForm.descripcion;
                    actualizaReservacion.reservado_para = reservacionForm.reservado_para;
                    actualizaReservacion.reservado_por = reservacionForm.reservado_por;
                    actualizaReservacion.fecha_inicio = reservacionForm.fecha_inicio;
                    actualizaReservacion.fecha_fin = reservacionForm.fecha_fin;

                    actualizaListaReservacion[indexReservacion] = {...actualizaReservacion};
                    setListaReservacion(actualizaListaReservacion);

                    // Actualizamos el evento para el calendario
                    let actualizaListaEvent: EventModel[] = [...listaEvent],
                    indexEvent = listaEvent.findIndex(e => e.id === reservacionForm.id?.toString());
                    let actualizaEventModel: EventModel = new EventModel();
                    actualizaEventModel.id = reservacionForm.id?.toString();
                    actualizaEventModel.title = reservacionForm.titulo;
                    actualizaEventModel.start = reservacionForm.fecha_inicio?.toISOString();
                    actualizaEventModel.end = reservacionForm.fecha_fin?.toISOString();
                    actualizaEventModel.extendedProps = { colorArea: listaAreaTrabajoSelect?.find(at => {return at.usuarios?.find(u => u.id === actualizaReservacion.reservado_para?.id)})?.color, espacioId: reservacionForm.res_espacio };

                    // Actualizamos el evento para calendario
                    actualizaListaEvent[indexEvent] = {...actualizaEventModel};
                    setListaEvent(actualizaListaEvent);

                    // Enviar email para la invitación
                    let actualizaEnviarEmail: EnviarEmailModel = new EnviarEmailModel(actualizaReservacion);
                    actualizaEnviarEmail.method = 'REQUEST'; 
                    actualizaEnviarEmail.isCreated = false;
                    
                    onEnviarEmail(actualizaEnviarEmail);

                    setVisibleFormDialog(false);

                    //setIsLoader(false)

                }else{
                    console.log(response.message);
                    onToastError('Error', 'No se puede acutalizar la reservación, intente mas tarde.');

                    //Loader
                    setIsLoader(false);
                    setVisibleFormDialog(false);
                }
            });  
        }else{
            const listaParticipanteUsuario: UsuarioModel[] = [...(reservacionForm.res_participantes as any)];

            // Eliminamos el objeto ID para poder guardar
            delete reservacionForm.id;
            delete reservacionForm.res_participantes;

            saveReservacionFormService((reservacionForm as any)).then((response: ResponseModel) => {
                if(response.status === 200){
                    // Asignamos el ID de reservacion (regresa)
                    reservacionForm.id = response.data.id;
                    
                    let nuevoReservacionParticipante: ReservacionParticipanteModel = new ReservacionParticipanteModel();
                    nuevoReservacionParticipante.id = response.data.id;

                    listaParticipanteUsuario?.forEach(participante => nuevoReservacionParticipante.participantes?.push(participante));

                    saveReservacionParticipanteService(nuevoReservacionParticipante).then((responseRP: ResponseModel) => {
                        if(responseRP.status === 200){
                            onToastSuccess('Correcto', 'Ha guardando la reservación.');

                            let reservacion: ReservacionModel = new ReservacionModel(reservacionForm);
                            reservacion.res_espacio = listaEspacio.find(espacio => espacio.id === reservacionForm.res_espacio);
                            reservacion.res_participantes = [];

                            // Asignamos los participantes (nuevo)
                            listaParticipanteUsuario?.forEach((usuario: UsuarioModel, index: number) => {
                                let participante: ParticipanteModel = new ParticipanteModel();
                                participante.id = responseRP.data[index].id;
                                participante.participante = usuario;

                                reservacion.res_participantes?.push(participante);
                            });

                            // Agregamos la reservacion en lista
                            setListaReservacion(r => [...r, reservacion]);

                            // Agregamos el evento de calendario
                            let nuevoEventModel: EventModel = new EventModel();
                            nuevoEventModel.id = reservacionForm.id?.toString();
                            nuevoEventModel.title = reservacionForm.titulo;
                            nuevoEventModel.start = reservacionForm.fecha_inicio?.toISOString();
                            nuevoEventModel.end = reservacionForm.fecha_fin?.toISOString();
                            //nuevoEventModel.backgroundColor = 'white';
                            //nuevoEventModel.borderColor = listaAreaTrabajoSelect?.find(at => {return at.usuarios?.find(u => u.id === reservacion.reservado_para?.id)})?.color;
                            nuevoEventModel.extendedProps = { colorArea: listaAreaTrabajoSelect?.find(at => {return at.usuarios?.find(u => u.id === reservacion.reservado_para?.id)})?.color, espacioId: reservacionForm.res_espacio };
                            // Agregamos el evento para calendario
                            setListaEvent(e => [...e, nuevoEventModel]);

                            // Enviar email para la invitación
                            let nuevoEnviarEmail: EnviarEmailModel = new EnviarEmailModel(reservacion);
                            nuevoEnviarEmail.method = 'REQUEST';
                            nuevoEnviarEmail.isCreated = true; 
                            
                            onEnviarEmail(nuevoEnviarEmail);
                            //setIsLoader(false)

                            // Cerramos el dialog de formulario
                            setVisibleFormDialog(false);
                        }else{
                            // Ocultammos Loader
                            setIsLoader(false);
                            setVisibleFormDialog(false);

                            console.log(response.message);
                            onToastError('Error', 'No se puede guardar los participantes, intente mas tarde.');
                        }
                    });
                }else{
                    console.log(response.message);
                    onToastError('Error', 'No se puede guardar la reservación, intente mas tarde.');

                    setIsLoader(false);
                    setVisibleFormDialog(false);
                }
            });
        }
      
    };

    const onEnviarEmail = (enviarEmail: EnviarEmailModel) =>{
        EnviarEmailService(enviarEmail).then(response => {
            if(response.status === 200){
                // Toast
                onToastSuccess('Correos', enviarEmail.res_participantes?.length as any > 1 ? 'Han enviado correcto.' : 'Ha enviado correcto.');
            }else{
                // Toast
                onToastError('Error', response.data as any);
            }
    
            //Loader
            setIsLoader(false);
            
        });
    }
  
    const onConfirmRemove = (id: number) => {
        // Mostaramos Loader
        setIsLoader(true);

        const reservacion = {...listaReservacion.find(r => r.id === id)};

        // Primero eliminamos los participantes
        reservacion.res_participantes?.forEach(participante => {
            removeParticipanteService(participante.id as any);
        });
        
        // Ahora eliminamos la reservación
        removeReservacionService(reservacion.id as any).then((response: ResponseModel) => {
            if(response.status === 200){
                onToastSuccess('Correcto', 'Ha eliminado la reservación.');

            }else{
                console.log(response.message);
                onToastError('Error', 'No se puede eliminar la reservación, intente mas tarde.');
            }

            // Cerramos el dialogo del formulario
            setVisibleFormDialog(false);
            // Ocultamos Loader
            //setIsLoader(false);

            // Eliminamos la reservacion
            let updateListaReservacion = [...listaReservacion].filter(r => r.id !== id);
            setListaReservacion(updateListaReservacion);
            // Eliminamos la reservacion
            let updateListaEvent = [...listaEvent].filter(e => e.id !== id.toString());
            setListaEvent(updateListaEvent);

            // Enviar email para la invitación
            let eliminaEnviarEmail: EnviarEmailModel = new EnviarEmailModel(reservacion);
            eliminaEnviarEmail.method = 'CANCEL'; 
            eliminaEnviarEmail.isCreated = false;
            eliminaEnviarEmail.descripcion = 'Se cancelo';
            
            onEnviarEmail(eliminaEnviarEmail);
        });
    }
  
    const onDayHeaderContent = (event: DayHeaderContentArg) =>{
        //console.log(event)
        return (
            <div className='imj-color-title'>
                {event.text}
            </div>
        )
        // if(event.view.type === 'dayGridMonth'){
        //     return (
        //         <div className='imj-color-title'>
        //             {event.text}
        //         </div>
        //     )
        // }else{
        //     return (
        //         <div>
        //             {event.text}
        //         </div>
        //     )
        // }
    }

    const onClickTimeGridWeek = () =>{
        // Asignamos la vista de TimeGridWeek
        calendarComponentRef.current.getApi().changeView('timeGridWeek');

        updateHeightRows();

        // Asignamos la vista de TimeGridWeek
        calendarComponentRef.current.getApi().changeView('timeGridWeek');

    }

    const onClickTimeGridDay = () => {
        // Asignamos la vista de TimeGridDay
        calendarComponentRef.current.getApi().changeView('timeGridDay');

        updateHeightRows();

        // Asignamos la vista de TimeGridDay
        calendarComponentRef.current.getApi().changeView('timeGridDay');
    }

    const updateHeightRows = (): void =>{
        var totalRows: number = 19, getHeight: number = document.getElementsByClassName('fc-scrollgrid-section-body')[0].clientHeight,
        dHeight = getHeight / totalRows,
        ultimoHeight = dHeight;

        if((dHeight * totalRows) > getHeight){
            ultimoHeight = dHeight - ((dHeight * totalRows) - getHeight);
        }else{
            ultimoHeight = ultimoHeight -= .4;
        }

        var rowsTimeGrid = document.getElementsByClassName('fc-timegrid-slot');

        for(let i = 0; i < rowsTimeGrid.length; i++) {
            if(i === (totalRows-1))
                (rowsTimeGrid[i] as any).style.height = dHeight + 'px';
            else
                (rowsTimeGrid[i] as any).style.height = ultimoHeight + 'px';
        }
    }

    const onDateClick = (event: DateClickArg) =>{
        if(event.view.type === 'dayGridMonth'){
            var viewDay = calendarComponentRef.current.getApi();
            viewDay.changeView('timeGridDay', event.dateStr);
            updateHeightRows();
        }
    }

    const onVerificarEsPropio = (reservacion: ReservacionModel): boolean =>{
        const user: UsuarioModel = loggedUser();
        return reservacion.reservado_por?.id === user.id || (rol.type === 'administrador' || rol.type === 'management');
    }

    const onVerificarFechas = (start: any, end: any, reservacionId?: string, espacioId?: number): boolean => {
        // Obtenemos la lista de evento solo la fehca de hoy
        const _listaEvent = listaEvent.filter(e => moment(e.start).format('L') === moment(start).format('L'));

        return _listaEvent.some(e => 
            ((moment(start).format('x') >= moment(e.start).format('x') && moment(start).format('x') < moment(e.end).format('x'))||
            (moment(end).format('x') > moment(e.start).format('x') && moment(end).format('x') <= moment(e.end).format('x')) || 
            (moment(start).format('x') < moment(e.start).format('x') && moment(end).format('x') > moment(e.end).format('x'))) && e.extendedProps.espacioId === espacioId && e.id !== reservacionId?.toString() );
    } 

    const onVerificarFechaAnterior = (start: any): boolean => {
        const fechaActual = moment().add(-1, 'days').format('X');
        // No se puede crear o cambiar de la fecha antes de la fecha actual
        return fechaActual > moment(start).format('x');
    }

    const onVerificarTerminaFecha = (end: any): boolean =>{
        return moment().format('x') > moment(end).format('x');
    }
  
    return (
        <div className="h-full">
            <BlockUI blocked={isLoader}>
                {isLoader ? <Loader /> : null} 
                {/* Form Dialog */}
                <FormDialog isInfo={isInfo} visibleFormDialog={visibleFormDialog} listaEspacio={listaEspacio} listaAreaTrabajoSelect={listaAreaTrabajoSelect} setVisibleFormDialog={setVisibleFormDialog} reservacion={reservacionForm} onClickGuardaReservacion={onClickGuardaReservacion} onConfirmRemove={onConfirmRemove} />
                <div className='container imj-calendario-de-ocupacion text-center'>
                    <h1 className='mt-0'>Reservación</h1>
                    <div className='content-calendar'>
                        <FullCalendar
                            ref={calendarComponentRef}
                            events={listaEvent}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,timeGridDay'
                            }}
                            dayHeaderFormat={{
                                weekday: 'long',
                                day: 'numeric'
                            }}
                            dayHeaderContent={onDayHeaderContent}
                            buttonText={{
                                today: 'Hoy',
                                month: 'Mes',
                                week: 'Semana',
                                day: 'Dia'
                            }}
                            locale='es-mx'
                            timeZone='local'
                            editable={true}
                            allDaySlot={false}
                            selectable={true}
                            selectMirror={true}
                            select={onSelect}
                            eventContent={onEventContent}
                            eventTimeFormat={{
                                hour: 'numeric',
                                minute: '2-digit',
                                meridiem: false
                            }}
                            eventChange={onEventChange}
                            eventColor='white'
                            eventBackgroundColor='white'
                            eventBorderColor='#e8474fc7'
                            dayMaxEvents={true}
                            dayMaxEventRows={1}
                            displayEventEnd={true}
                            weekNumbers={true}
                            weekNumberFormat={{
                                day: '2-digit',
                                week: 'long'
                            }}
                            eventOverlap={true}
                            slotEventOverlap={false}
                            eventClick={onEventClick}
                            height='100%'
                            slotMinTime='09:00:00'
                            slotMaxTime='18:30:00'
                            slotLabelInterval='00:30:00'
                            slotLabelFormat={{hour: 'numeric', minute: '2-digit', }}
                            customButtons={{
                                timeGridWeek:{
                                    text: 'Semana',
                                    click: () => onClickTimeGridWeek(),
                                },
                                timeGridDay:{
                                    text: 'Día',
                                    click: () => onClickTimeGridDay()
                                }
                            }}
                            windowResize={(event) => {
                                // console.log('eeeeeeeeeeeeeeeee')
                                // if (window.innerWidth >= 768 ) {
                                //     calendarComponentRef.current.getApi().changeView('dayGridMonth');
                                //     /* More code */
                                // } else {
                                //     calendarComponentRef.current.getApi().changeView('timeGridDay');
                                //     /* More code */
                                // }
                            }}
                            moreLinkText='mas'
                            dateClick={onDateClick}
                            selectLongPressDelay={2}
                            
                            //contentHeight='auto'
                        />
                        
                    </div>
                </div>
            </BlockUI>
        </div>
    );
}

export default CalendarioDeOcupacion;